import React from 'react';
import {
  Box,
  Divider,
  FlexBox,
  IconFontAwesome,
  InfoPanel,
  Spacer,
  TextBody,
  TextDense,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import ReactI18n from 'react-i18n';
import { includes, findIndex, find } from 'lodash';
import moment from 'moment/moment';
import { formatCurrency } from '../../../../shared/helpers';
import { selectCheckoutState } from '../../../redux/slices/checkout';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';

const CheckoutPricingTotalAndPayment = () => {
  const { isLargeTabletOrGreater } = useDetectMobile();
  const checkoutState = useSelector(selectCheckoutState);
  const brand = useSelector(state => state.brand);
  const translate = ReactI18n.getIntlMessage;
  const isInstantBooking = checkoutState.availability.instant_booking;
  const priceTotal =
    checkoutState.pricing.promotional_total < checkoutState.checkoutTotal
      ? checkoutState.pricing.promotional_total
      : checkoutState.checkoutTotal;

  const calculateBalanceDueDate = () => {
    const dueDates = [];

    if (checkoutState.pricing.booking_deposits) {
      dueDates.push(
        checkoutState.pricing.booking_deposits.remaining_balance_due_date
      );
    }

    const availableDueDates = [
      'day90',
      'day60',
      'day30',
      'day15',
      'day7',
      'check_in_date',
      'manual'
    ];

    if (dueDates.length > 0) {
      return find(availableDueDates, date => includes(dueDates, date));
    } else {
      return availableDueDates[0];
    }
  };

  const renderBalanceDueText = () => {
    const dueDateOptions = [
      { value: 'day90', label: '90 days prior to check-in' },
      { value: 'day60', label: '60 days prior to check-in' },
      { value: 'day30', label: '30 days prior to check-in' },
      { value: 'day15', label: '15 days prior to check-in' },
      { value: 'day7', label: '7 days prior to check-in' },
      { value: 'check_in_date', label: 'on the check-in date' },
      { value: 'manual', label: 'before check-in' }
    ];
    const dueDate = calculateBalanceDueDate();
    const index = findIndex(dueDateOptions, option => option.value === dueDate);
    return dueDateOptions[index].label;
  };

  const bookingOutsideRemainingBalanceDueDate = bookingDeposit => {
    if (!bookingDeposit || bookingDeposit.length == 0) {
      return false;
    }

    const timeNow = new Date();
    const outsideRemainingBalanceDueDate = true;
    const remainingBalanceOption = bookingDeposit.remaining_balance_due_date;
    const checkIn = checkoutState.checkInDate;

    if (['manual', 'check_in_date'].indexOf(remainingBalanceOption) > -1) {
      return true;
    }

    const dayOption = parseInt(remainingBalanceOption.replace('day', ''));
    const balanceDueDate = moment(
      timeNow.setDate(timeNow.getDate() + dayOption)
    );

    return balanceDueDate.isBefore(checkIn);
  };

  const calculateDeposits = bookingDeposit => {
    let total = 0;

    if (bookingOutsideRemainingBalanceDueDate(bookingDeposit)) {
      total = bookingDeposit.value;
    } else {
      total =
        checkoutState.pricing.promotional_total < checkoutState.checkoutTotal
          ? checkoutState.pricing.promotional_total
          : checkoutState.checkoutTotal;
    }

    return total;
  };

  return (
    <>
      <Box
        padding={isLargeTabletOrGreater ? 's' : null}
        paddingVertical={isLargeTabletOrGreater ? null : 's'}
      >
        <FlexBox>
          <Box>
            <TextBody weight="semibold" textColor="dark-gray">
              {translate(`cx.global.total`)}
            </TextBody>
          </Box>
          <FlexBox flex="1" justifyContent="flex-end">
            <TextBody weight="semibold" textColor="dark-gray">
              {formatCurrency(priceTotal, brand.currency)}
            </TextBody>
          </FlexBox>
        </FlexBox>
      </Box>
      <Divider />
      <Box
        padding={isLargeTabletOrGreater ? 's' : null}
        paddingTop={isLargeTabletOrGreater ? null : 's'}
      >
        <FlexBox>
          <Box>
            <TextH2>
              {isInstantBooking ? 'Due Now' : 'Due at Confirmation'}
            </TextH2>
          </Box>
          <FlexBox flex="1" justifyContent="flex-end">
            <TextH2>
              {checkoutState.pricing.booking_deposits &&
              checkoutState.pricing.booking_deposits.value < priceTotal
                ? formatCurrency(
                    calculateDeposits(checkoutState.pricing.booking_deposits),
                    brand.currency
                  )
                : formatCurrency(priceTotal, brand.currency)}
            </TextH2>
          </FlexBox>
        </FlexBox>
        {checkoutState.pricing.booking_deposits &&
          checkoutState.pricing.booking_deposits.value < priceTotal &&
          bookingOutsideRemainingBalanceDueDate(
            checkoutState.pricing.booking_deposits
          ) && (
            <>
              <TextBody>{`Pay the rest ${renderBalanceDueText()}`}</TextBody>
              <Spacer />
              <TextDense>
                {`A booking deposit of ${formatCurrency(
                  checkoutState.pricing.booking_deposits.value,
                  brand.currency
                )} is all you need to pay up-front; the rest of your
                payment is due ${renderBalanceDueText()}. Your card will
                not be charged until the owner confirms your booking.`}
              </TextDense>
            </>
          )}
        {checkoutState.pricing.security_deposits.value > 0 && (
          <>
            <Spacer />
            <InfoPanel
              color="blue"
              description={`A Refundable Security Deposit of ${formatCurrency(
                checkoutState.pricing.security_deposits.value,
                brand.currency
              )} is due 2 days prior to check-in.`}
              headline={`Security Deposity: ${formatCurrency(
                checkoutState.pricing.security_deposits.value,
                brand.currency
              )}`}
              icon={<IconFontAwesome name="infoCircle" />}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default CheckoutPricingTotalAndPayment;
