import React, { useEffect, useState } from 'react';
import {
  Box,
  CallToActionButton,
  Divider,
  FlexBox,
  IconFontAwesome,
  InfoPanel,
  Label,
  Panel,
  Spacer,
  StandByOverlay,
  TextCaption,
  TextH2,
  InputCheckbox,
  Modal,
  FormField
} from '@directsoftware/ui-kit-web-admin';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { toast } from 'react-toastify';
import get from 'lodash/get';
import CheckoutCtaV2 from '../shared/checkout-cta-v2';
import { baseLynnbrookConfig } from '../../../shared/data';
import { selectCheckoutState } from '../../redux/slices/checkout';
import {
  selectPaymentState,
  updatePaymentSlice
} from '../../redux/slices/payment';

const LynnbrookPaymentV2 = props => {
  const dispatch = useDispatch();
  const lynnbrookState = props.usePaymentState
    ? useSelector(selectPaymentState)
    : useSelector(selectCheckoutState);
  const brand = useSelector(state => state.brand);
  const [lynnbrookCardAccepted, setLynnbrookCardAccepted] = useState(false);
  const [lynnbrookErrors, setLynnbrookErrors] = useState([]);
  const [lynnbrookPaymentMethod, setLynnbrookPaymentMethod] = useState(null);
  const [showStandBy, setShowStandBy] = useState(false);
  const [cardError, setCardError] = useState(false);
  const [showConsentForm, setShowConsentForm] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);

  const submitLynnbrook = () => {
    if (typeof aptxFields !== 'undefined') {
      aptxFields.submit('CARD');
    }
  };

  const renderError = key => {
    if (lynnbrookErrors.length > 0) {
      const findError = lynnbrookErrors.filter(error => error.field === key);
      return findError[0]?.type ? (
        <TextCaption className="error-red-text" isFullWidth>
          {findError[0].type}
        </TextCaption>
      ) : null;
    }
    return null;
  };

  const renderMethodError = key => {
    if (lynnbrookErrors.length > 0) {
      const findError = lynnbrookErrors.filter(error => error.type === key);
      return findError[0]?.type ? (
        <TextCaption className="error-red-text" isFullWidth>
          Something was wrong with your card. Please check your info and try
          again.
        </TextCaption>
      ) : null;
    }
    return null;
  };

  const handleCompletePayment = () => {
    setShowStandBy(true);
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/listings/${
          lynnbrookState.listing.id
        }/process_payment`,
        {
          charge_amount: parseFloat(props.chargeAmount),
          booking_id: lynnbrookState.booking.id,
          customer_email: lynnbrookState.customerEmail,
          customer_name: lynnbrookState.customerName,
          customer_telephone: lynnbrookState.customerTelephone,
          customer_date_of_birth: lynnbrookState.customerDateOfBirth,
          stripe_customer_id: lynnbrookState.stripeCustomerId,
          stripe_token: lynnbrookState.stripeCustomerId,
          method_id: lynnbrookPaymentMethod.methodId
        }
      )
      .then(() => {
        dispatch(updatePaymentSlice({ lynnbrookCardPaymentFailed: false }));
        window.location = window.location;
      })
      .catch(data => {
        dispatch(updatePaymentSlice({ lynnbrookCardPaymentFailed: true }));
        setShowStandBy(false);
        props.fetchLynnbrookConfig(
          lynnbrookState.lynnbrookConfig.accountTargetId
        );
        toast.error(data.responseJSON.error);
        window.location = window.location;
      });
  };

  const handleCompleteBooking = () => {
    setShowStandBy(true);
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/checkout_booking/${
          lynnbrookState.listing.id
        }`,
        {
          unit_id: lynnbrookState.unit.id,
          booking_range: JSON.stringify(lynnbrookState.bookingDaysInclusive),
          check_in: lynnbrookState.checkInDate.format('DD-MM-YYYY'),
          check_out: lynnbrookState.checkOutDate.format('DD-MM-YYYY'),
          num_guests: lynnbrookState.guests,
          customer_email: lynnbrookState.customerEmail,
          customer_name: `${lynnbrookState.customerFirstName} ${
            lynnbrookState.customerLastName
          }`,
          customer_telephone: lynnbrookState.customerTelephone,
          customer_date_of_birth: lynnbrookState.customerDateOfBirth,
          consent_given: consentGiven,
          delivery_location: lynnbrookState?.deliveryLocation,
          adr_street: lynnbrookState.adrStreet,
          adr_city: lynnbrookState.adrCity,
          adr_state: lynnbrookState.adrState,
          adr_country: lynnbrookState.adrCountry,
          adr_zip: lynnbrookState.adrPostalCode,
          addon_fee_ids: lynnbrookState.addonFeeIds,
          stripe_customer_id: lynnbrookState.stripeCustomerId,
          coupon_code: lynnbrookState.couponCode,
          room_type_booking: lynnbrookState.listing.is_room_type,
          quote_id: lynnbrookState.quoteId,
          method_id: lynnbrookPaymentMethod.methodId
        },
        { headers: { 'Content-Type': 'application/json' } }
      )
      .then(response => {
        setCardError(false);
        const data = response.data;
        if (brand.brand_info.google_events) {
          gtag('event', 'purchase', {
            transaction_id: data.booking_code,
            affiliation: 'Direct',
            value: lynnbrookState.pricing.total,
            currency: 'USD',
            tax: lynnbrookState.pricing.taxes,
            shipping: 0
          });
        }
        if (brand.brand_info.facebook_pixel) {
          fbq('track', 'Purchase', {
            currency: 'USD',
            value: lynnbrookState.pricing.total
          });
        }
        if (
          lynnbrookState.verifyImage ||
          lynnbrookState.verifySignature ||
          lynnbrookState.verifyAge ||
          lynnbrookState.verifyAddress
        ) {
          window.location = `${'/my-bookings/verification/' +
            `${props.slug}/`}${data.booking_code}${props.urlLocation?.search}`;
        } else {
          window.location = `${'/my-bookings/receipt/' + `${props.slug}/`}${
            data.booking_code
          }${props.urlLocation?.search}`;
        }
      })
      .catch(data => {
        setShowStandBy(false);
        const err = get(
          data,
          'response.data.error[1][0]',
          'booking could not be processed, try again later'
        ).replace('Lynnbrook ', '');
        setCardError(err);
        toast.error(err);
        props?.fetchLynbrookConfig(
          checkoutState.lynnbrookConfig.accountTargetId
        );
      });
  };

  const renderFields = () => {
    const test = setInterval(() => {
      if (typeof aptxFields !== 'undefined') {
        clearInterval(test);
        const config = {
          ...baseLynnbrookConfig,
          ...lynnbrookState.lynnbrookConfig,
          domain: window.location.origin,
          production: process.env.DIRECT_URL === 'https://app.getdirect.io',
          callback: response => {
            const res = JSON.parse(response);
            setLynnbrookErrors(res?.errors || []);
            delete res.errors;
            if (res.duplicateCardMethods) {
              const latestMethod = res.duplicateCardMethods.slice(-1);
              setLynnbrookPaymentMethod(latestMethod[0]);
            } else {
              setLynnbrookPaymentMethod(res);
            }
          }
        };

        aptxFields.setup(config);
      }
    }, 250);
  };

  const checkAuthenticate = () => {
    if (brand.organization.integrations) {
      const authenticate = brand.organization.integrations.filter(
        integration => integration.integration_name === 'authenticate'
      )[0];

      if (
        authenticate &&
        authenticate.additional_integration_details &&
        authenticate.additional_integration_details.medallion_workflow_id
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(
    () => {
      if (lynnbrookPaymentMethod?.methodId) {
        setLynnbrookCardAccepted(true);
      } else {
        setLynnbrookCardAccepted(false);
      }
    },
    [lynnbrookPaymentMethod]
  );

  useEffect(
    () => {
      setCardError(false);
      if (lynnbrookState.accountPersonCreated && !lynnbrookCardAccepted) {
        renderFields();
      }
    },
    [lynnbrookState.accountPersonCreated, lynnbrookCardAccepted]
  );

  return (
    <>
      <StandByOverlay
        reveal={showStandBy}
        position="fixed"
        headline={
          props.chargeAmount > 0
            ? 'Completing your payment...'
            : 'Completing your booking...'
        }
      />
      <FlexBox justifyContent="space-between" alignItems="center">
        <Box>
          <TextH2>Payment Info</TextH2>
        </Box>
        {lynnbrookCardAccepted && (
          <Box>
            <CallToActionButton
              onClick={() => setLynnbrookCardAccepted(false)}
              appearance="ghost"
              customButtonColor={brand.brand_info.colors?.color_primary}
              customTextColor={brand.brand_info.colors?.color_primary_text}
            >
              Edit
            </CallToActionButton>
          </Box>
        )}
      </FlexBox>
      <Spacer />
      {lynnbrookState.accountPersonCreated ? (
        <>
          {lynnbrookCardAccepted ? (
            <Panel padding="s">
              <Box>
                {`Card: ${lynnbrookPaymentMethod?.brand ||
                  lynnbrookPaymentMethod?.brandType} ${
                  lynnbrookPaymentMethod?.lastFour
                }`}
              </Box>
              <Box>{lynnbrookPaymentMethod?.accountHolder}</Box>
              {cardError && <InfoPanel color="red" headline={cardError} />}
            </Panel>
          ) : (
            <>
              <Box>
                <Label htmlFor="aptexx-card-name-container">
                  Cardholder Name:
                </Label>
              </Box>
              <Box
                renderAs="span"
                id="aptexx-card-name-container"
                className="lynnbrook-iframe-input"
              />
              {renderError('CARD_NAME')}
              <Box>
                <Label htmlFor="aptexx-card-number-container">
                  Card Number:
                </Label>
              </Box>
              <Box
                renderAs="span"
                id="aptexx-card-number-container"
                className="lynnbrook-iframe-input"
              />
              {renderError('CARD_NUMBER')}
              <FlexBox gap="s">
                <Box flex="1">
                  <Box>
                    <Label htmlFor="aptexx-card-expiration-date-container">
                      Exp date:
                    </Label>
                  </Box>
                  <Box
                    renderAs="span"
                    id="aptexx-card-expiration-date-container"
                    className="lynnbrook-iframe-input"
                  />
                  {renderError('EXPIRATION_DATE')}
                </Box>
                <Box flex="1">
                  <Box>
                    <Label htmlFor="aptexx-card-zip-code-container">Zip:</Label>
                  </Box>
                  <Box
                    renderAs="span"
                    id="aptexx-card-zip-code-container"
                    className="lynnbrook-iframe-input"
                  />
                  {renderError('ZIP_CODE')}
                </Box>
              </FlexBox>
              {renderMethodError('METHOD_ERROR')}
            </>
          )}
        </>
      ) : (
        <InfoPanel
          icon={<IconFontAwesome name="infoCircle" />}
          color="blue"
          headline="Please fill out your contact info first."
        />
      )}
      {!lynnbrookCardAccepted && (
        <>
          <Spacer size="m" />
          <CallToActionButton
            onClick={() => submitLynnbrook()}
            size="large"
            isFullWidth
            isDisabled={!lynnbrookState.accountPersonCreated}
            customButtonColor={brand.brand_info.colors?.color_primary}
            customTextColor={brand.brand_info.colors?.color_primary_text}
          >
            Add Card
          </CallToActionButton>
        </>
      )}
      <Spacer size="m" />
      <Divider />
      <Spacer size="l" />
      {props.isAuthenticate && (
        <>
          <Spacer size="l" />
          <small style={{ display: 'flex', flexDirection: 'row' }}>
            <InputCheckbox onChange={() => setConsentGiven(!consentGiven)} />
            <label htmlFor="consentCheckbox">
              {' '}
              By clicking this checkbox, you are agreeing to pay the full amount
              of the booking, and you are consenting to the necessary checks and
              verifications for insurance or other background research purposes
              listed <a onClick={() => setShowConsentForm(true)}>here</a>{' '}
            </label>
          </small>
          <Modal
            title="Consent Form"
            reveal={showConsentForm}
            size="m"
            closeOnClick={() => setShowConsentForm(false)}
          >
            <Modal.Content contentIsScrollable>
              <Box paddingVertical="s" paddingHorizontal="xs">
                <div>
                  Authorization to Obtain a Consumer Report Pursuant to the
                  federal Fair Credit Reporting Act (“FCRA”), I hereby authorize
                  Authenticating.com LLC and its designated agents and
                  representatives to conduct a comprehensive review of my
                  background through a consumer report and/or an investigative
                  consumer report that may be used as a factor in establishing
                  my eligibility for credit, insurance or for any other purpose
                  in the FCRA. I understand that the scope of the consumer
                  report/investigative consumer report may include, but is not
                  limited to, the following areas: verification of Social
                  Security number; current and previous residences; employment
                  history, including all personnel files; education; references;
                  credit history and reports; criminal history, including
                  records from any criminal justice agency in any or all
                  federal, state or county jurisdictions; birth records; motor
                  vehicle records, including traffic citations and registration;
                  and any other public records.
                  <br />
                  I, {lynnbrookState.customerFirstName}{' '}
                  {lynnbrookState.customerLastName}
                  authorize the complete release of these records or data
                  pertaining to me that an individual, company, firm,
                  corporation or public agency may have. I hereby authorize and
                  request any present or former employer, school, police
                  department, financial institution or other persons having
                  personal knowledge of me to furnish Authenticating.com LLC or
                  its designated agents with any and all information in their
                  possession regarding me in connection with the use of Direct
                  Software's product, service or experience I am signing up for.
                  I am authorizing that a photocopy of this authorization be
                  accepted with the same authority as the original. I understand
                  that, pursuant to the federal Fair Credit Reporting Act, if
                  any adverse action is to be taken based upon the consumer
                  report, a copy of the report and a summary of the consumer’s
                  rights will be provided to me. I further understand that by
                  typing my name here I am signing this authorization
                  electronically.
                </div>
              </Box>
            </Modal.Content>
          </Modal>
        </>
      )}
      <CheckoutCtaV2
        {...props}
        handleSubmit={
          props.chargeAmount > 0 ? handleCompletePayment : handleCompleteBooking
        }
        disableCta={
          !lynnbrookCardAccepted ||
          !lynnbrookState.accountPersonCreated ||
          showStandBy
        }
      />
    </>
  );
};

export default LynnbrookPaymentV2;
