import React, { useState } from 'react';
import {
  Panel,
  FlexBox,
  Box,
  CallToActionLink,
  CallToActionButton,
  IconFontAwesome,
  TextH2,
  TextDense,
  Divider,
  Spacer,
  TextCaption,
  TextBody,
  Modal,
  FormField,
  Pill,
  StandByOverlay
} from '@directsoftware/ui-kit-web-admin';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { selectReceiptState } from '../../../redux/slices/receipt';
import {
  buildAddress,
  buildDateDisplay,
  renderName
} from '../../listings/resources/shared-functions';
import ReceiptSummaryConfirmation from './receipt-summary-confirmation';
import { useDetectMobile } from '../../../../shared/hooks/useDetectMobile';
import { usStates } from '../../../../shared/data';

const ReceiptSummary = props => {
  const brand = useSelector(state => state.brand);
  const { isMobile } = useDetectMobile();
  const receiptState = useSelector(selectReceiptState);
  const [openModal, setOpenModal] = useState(false);
  const [dlNumber, setDlNumber] = useState(null);
  const [dlState, setDlState] = useState('');
  const [verifyingLicense, setVerifyingLicense] = useState(false);
  const [dlNumberError, setDlNumberError] = useState(false);
  const [dlStateError, setDlStateError] = useState(false);
  const [overlayIconName, setOverlayIconName] = useState('checkCircle');
  const [overlayHeadline, setOverlayHeadline] = useState(null);
  const [overlayDescription, setOverlayDescription] = useState(null);
  const [overlayIconColor, setOverlayIconColor] = useState('purple');
  const [showDLVerification, setShowDLVerification] = useState(true);
  const [allowDlVerifyRetry, setAllowDlVerifyRetry] = useState(false);

  const checkNeedsMbaVerification = policy => {
    const prefix = policy ? policy?.substring(0, 4) : '';
    return prefix === '01A3';
  };

  const checkAuthenticate = () => {
    if (brand.organization.integrations) {
      const authenticate = brand.organization.integrations.filter(
        integration => integration.integration_name === 'authenticate'
      )[0];

      if (
        authenticate &&
        authenticate.additional_integration_details &&
        authenticate.additional_integration_details.medallion_workflow_id &&
        !receiptState.mvr_verification_attempted
      ) {
        return true;
      }
    }
    return false;
  };

  const submitLicenseInfo = () => {
    setVerifyingLicense(true);
    setOverlayIconName('spinner');
    setOverlayHeadline('Verifying...');
    setAllowDlVerifyRetry(false);
    axios
      .post(
        `${process.env.DIRECT_URL}/api/v2/my-bookings/driver_verification/${
          receiptState.booking.booking_code
        }`,
        {
          license_number: dlNumber,
          state_abbr: dlState
        }
      )
      .then(response => {
        const data = response.data;
        if (data.mvr_verification_status === 'verified') {
          setOverlayIconName('checkCircle');
          setOverlayHeadline('Driver Verification Submitted');
          setOverlayIconColor('green');
          setShowDLVerification(false);
        } else {
          setOverlayIconName('times');
          setOverlayIconColor('red');
          setOverlayHeadline('Unable to Complete Verification');
          setOverlayDescription(
            'There was a problem trying to verify your information. Please contact the host to help get this resolved.'
          );
          setShowDLVerification(false);
        }
      })
      .catch(error => {
        console.log('error', error.response);
        setOverlayIconName('times');
        setOverlayIconColor('red');
        setOverlayHeadline('Unable to Complete Verification');
        setOverlayDescription(
          'There was a problem trying to verify your info. Please try again or contact the host if this problem persists.'
        );
        setAllowDlVerifyRetry(true);
      });
  };

  return (
    <>
      <Panel
        addShadow={!isMobile}
        removeBorder={isMobile}
        className="receiptSummary__panel"
      >
        <FlexBox className="receiptSummary">
          <Box setPositionRelative className="receiptSummary__left">
            {receiptState.featured_image ? (
              <img
                src={receiptState.featured_image.image.small.url}
                alt=""
                className="receiptSummary__listingImage"
              />
            ) : (
              <FlexBox className="receiptSummary__noListingImage">
                <IconFontAwesome name="image" />
              </FlexBox>
            )}
          </Box>
          <Box className="receiptSummary__right" padding={isMobile ? 's' : 'm'}>
            <ReceiptSummaryConfirmation />
            <TextH2 truncate>{renderName(receiptState)}</TextH2>
            <TextDense isFullWidth>
              {buildAddress(receiptState.location, true)}
            </TextDense>
            <Divider padding="s" />
            <FlexBox>
              <Box flex="1">
                <TextCaption
                  textTransform="uppercase"
                  weight="regular"
                  isFullWidth
                >
                  {`${receiptState.nights} Night Stay`}
                </TextCaption>
                <Spacer size="xxs" />
                <TextBody weight="semibold" textColor="dark-gray">
                  {buildDateDisplay(
                    receiptState.booking.check_in,
                    receiptState.booking.check_out
                  )}
                </TextBody>
              </Box>
              <Box flex="1">
                <TextCaption
                  textTransform="uppercase"
                  weight="regular"
                  isFullWidth
                >
                  Booking Code
                </TextCaption>
                <Spacer size="xxs" />
                <TextBody weight="semibold" textColor="dark-gray">
                  {receiptState.booking.booking_code}
                </TextBody>
              </Box>
            </FlexBox>
            {brand.isRvFleet &&
              receiptState?.unit?.mba_insurance_policy_number &&
              checkNeedsMbaVerification(
                receiptState.unit.mba_insurance_policy_number
              ) && (
                <>
                  <Divider padding="s" />
                  <FlexBox justifyContent="space-between" alignItems="center">
                    <Box>
                      <Pill color="yellow">NEEDS VERIFICATION</Pill>
                      <Spacer size="xxs" />
                      <TextBody
                        weight="semibold"
                        textColor="dark-gray"
                        isFullWidth
                      >
                        Insurance Verification
                      </TextBody>
                    </Box>
                    <CallToActionLink
                      href={`https://mbainsurance.net/choice/index.php?PolicyNumber=${
                        receiptState.unit.mba_insurance_policy_number
                      }`}
                      type="link"
                      openNewTab
                    >
                      Verify
                    </CallToActionLink>
                  </FlexBox>
                </>
              )}
            {checkAuthenticate() && showDLVerification && (
              <>
                <Divider padding="s" />
                <FlexBox justifyContent="space-between" alignItems="center">
                  <Box>
                    <Pill color="yellow">NEEDS VERIFICATION</Pill>
                    <Spacer size="xxs" />
                    <TextBody
                      weight="semibold"
                      textColor="dark-gray"
                      isFullWidth
                    >
                      Driver's License
                    </TextBody>
                  </Box>
                  <CallToActionButton onClick={() => setOpenModal(true)}>
                    Verify
                  </CallToActionButton>
                </FlexBox>
              </>
            )}
          </Box>
        </FlexBox>
        {isMobile && <Divider />}
      </Panel>
      <Box className="receiptSummary__modal">
        <Modal
          reveal={openModal}
          closeOnClick={() => {
            setOpenModal(false);
          }}
          title="Driver's License Verification"
          size="form"
        >
          <Modal.Content>
            <Box padding="s" setPositionRelative>
              <StandByOverlay
                position="absolute"
                headline={overlayHeadline}
                description={overlayDescription}
                reveal={verifyingLicense}
                iconPresetColor={overlayIconColor}
                icon={
                  <IconFontAwesome
                    name={overlayIconName}
                    spin={overlayIconName === 'spinner'}
                  />
                }
                actionLabel="Retry"
                actionOnClick={
                  allowDlVerifyRetry ? () => setVerifyingLicense(false) : null
                }
              />
              <TextH2>We need to verify your Driver's License.</TextH2>
              <Spacer size="xs" />
              <TextBody>
                The owner requires a little more information to help verify your
                status as a driver. Please fill out the info below so we can get
                you driving!
              </TextBody>
              <Spacer size="m" />
              <FormField
                labelText="Drivers License Number"
                inputProps={{
                  onChange: e => setDlNumber(e.target.value),
                  value: dlNumber,
                  inputWidth: 'm',
                  isError: dlNumberError
                }}
              />
              <FormField
                labelText="Drivers License State"
                inputType="select"
                inputProps={{
                  onChange: e => setDlState(e.value),
                  options: usStates,
                  value: usStates.filter(state => state.value === dlState),
                  inputWidth: 's',
                  isError: dlStateError
                }}
              />
              <Spacer />
            </Box>
          </Modal.Content>
          <Modal.Footer
            primaryOnClick={() => {
              if (dlNumber && dlState !== '') {
                setDlStateError(false);
                setDlNumberError(false);
                submitLicenseInfo();
              } else {
                if (!dlNumber) setDlNumberError(true);
                if (dlState === '') setDlStateError(true);
              }
            }}
            primaryLabel="Verify"
            primaryIsDisabled={verifyingLicense}
            secondaryOnClick={() => setOpenModal(false)}
            secondaryLabel="Cancel"
            secondaryIsDisabled={verifyingLicense}
          />
        </Modal>
      </Box>
    </>
  );
};

ReceiptSummary.propTypes = {};

export default ReceiptSummary;
