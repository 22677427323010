// Dependencies
// -----------------------------------------------
import React from 'react';
import MaskedInput from 'react-maskedinput';
import ReactI18n from 'react-i18n';

// Components
// -----------------------------------------------
import {
  Box,
  CallToActionButton,
  Divider,
  FlexBox,
  FormField,
  Label,
  Spacer,
  TextCaption,
  TextH2
} from '@directsoftware/ui-kit-web-admin';
import {
  cardTypeMask,
  checkErrorsCardNumber,
  checkErrorsCardExpiry,
  checkErrorsCardCvv,
  checkErrorsCustomerEmail,
  checkErrorsCustomerName,
  checkErrorsCustomerPostalCode,
  checkErrorsCustomerTelephone,
  validateCardNumber,
  validateCardExpiry,
  validateCardCvv,
  validateCustomerEmail,
  validateCustomerName,
  validateCustomerPostalCode,
  validateCustomerTelephone
} from '../credit-card';
import PaymentCard from '../errors/payment-card';
import PaymentCustomer from '../errors/payment-customer';

// -----------------------------------------------
// COMPONENT->FORM(RECEIPT) ----------------------
// -----------------------------------------------
export default class FormV2 extends React.Component {
  // Constructor
  // ---------------------------------------------
  constructor(props) {
    super(props);

    this.state = {
      cardNumber: '',
      cardExpiry: '',
      cardCvv: '',
      cardTypeMask: '1111 1111 1111 1111',
      customerEmail: this.props.customerEmail || '',
      customerName: this.props.customerName || '',
      customerPostalCode: this.props.customerPostalCode || '',
      customerTelephone: this.props.customerTelephone || '',
      cardNumberValid: false,
      cardExpiryValid: false,
      cardCvvValid: false,
      customerEmailValid: false,
      customerNameValid: false,
      customerPostalCodeValid: false,
      customerTelephoneValid: false,
      cardNumberError: null,
      cardExpiryError: null,
      cardCvvError: null,
      customerEmailError: null,
      customerNameError: null,
      customerPostalCodeError: null,
      customerTelephoneError: null
    };
  }

  // On Change
  // ---------------------------------------------
  onChange = e => {
    e.preventDefault();
    const stateChange = {};
    stateChange[e.target.name] = e.target.value;
    if (e.target.classList.contains('invalid')) {
      this.checkValidity(e.target.name, e.target.value);
    }
    this.setState(stateChange);
    if (e.target.name === 'cardNumber') {
      this.setState({ cardTypeMask: cardTypeMask(e.target.value) });
    }
  };

  // On Blur
  // ---------------------------------------------
  onBlur = e => {
    this.checkValidity(e.target.name, e.target.value);
  };

  // Check Validity
  // ---------------------------------------------
  checkValidity = (type, val) => {
    let validity = false;
    let error = null;

    switch (type) {
      case 'cardCvv':
        validity = validateCardCvv(val);
        error = checkErrorsCardCvv(val);
        break;
      case 'cardExpiry':
        validity = validateCardExpiry(val);
        error = checkErrorsCardExpiry(val);
        break;
      case 'cardNumber':
        validity = validateCardNumber(val);
        error = checkErrorsCardNumber(val);
        break;
      case 'customerEmail':
        validity = validateCustomerEmail(val);
        error = checkErrorsCustomerEmail(val);
        break;
      case 'customerName':
        validity = validateCustomerName(val);
        error = checkErrorsCustomerName(val);
        break;
      case 'customerPostalCode':
        validity = validateCustomerPostalCode(val);
        error = checkErrorsCustomerPostalCode(val);
        break;
      case 'customerTelephone':
        validity = validateCustomerTelephone(val);
        error = checkErrorsCustomerTelephone(val);
        break;
    }

    const validityChange = {};
    const errorChange = {};
    validityChange[`${type}Valid`] = validity;
    errorChange[`${type}Error`] = error;

    this.setState(validityChange);
    this.setState(errorChange);
  };

  // Handle Submit
  // ---------------------------------------------
  handleSubmit = () => {
    this.submitBtn.setAttribute('disabled', 'disabled');

    this.props.processSecurityDeposit(
      this.state.cardNumber,
      this.state.cardExpiry,
      this.state.cardCvv,
      this.state.customerEmail,
      this.state.customerName,
      this.state.customerPostalCode,
      this.state.customerTelephone
    );
  };

  // Has Invalid Fields
  // ---------------------------------------------
  hasInvalidFields = () => {
    return !(
      this.state.cardNumberValid &&
      this.state.cardExpiryValid &&
      this.state.cardCvvValid &&
      this.state.customerEmailValid &&
      this.state.customerNameValid &&
      this.state.customerPostalCodeValid &&
      this.state.customerTelephoneValid
    );
  };

  // Build Field Status
  // ---------------------------------------------
  buildFieldStatus = type => {
    const typeError = this.state[`${type}Error`];
    const typeValidity = this.state[`${type}Valid`];

    if (typeValidity === true) {
      return 'valid';
    } else if (typeError === 'empty' || typeError === null) {
      return '';
    }
    return 'invalid';
  };

  // Build Section Status
  // ---------------------------------------------
  buildSectionStatus = type => {
    if (type === 'cc') {
      if (
        this.state.cardNumberValid &&
        this.state.cardExpiryValid &&
        this.state.cardCvvValid
      ) {
        return 'valid';
      } else if (
        (this.state.cardNumberError &&
          this.state.cardNumberError !== 'empty') ||
        (this.state.cardExpiryError &&
          this.state.cardExpiryError !== 'empty') ||
        (this.state.cardCvvError && this.state.cardCvvError !== 'empty')
      ) {
        return 'invalid';
      } else {
        return '';
      }
    }
  };

  onChangePhone = phone => {
    this.setState(
      {
        customerTelephone: phone
      },
      () => {
        this.checkValidity('customerTelephone', this.state.customerTelephone);
      }
    );
  };

  onBlurPhone = () => {
    this.checkValidity('customerTelephone', this.state.customerTelephone);
  };

  // Render
  // ---------------------------------------------
  render() {
    const translate = ReactI18n.getIntlMessage;

    return (
      <>
        <Box className={`paymentForm ${this.buildSectionStatus('cc')}`}>
          <TextH2>Payment Information</TextH2>
          <Spacer />
          <PaymentCard
            errors={[
              { param: 'cardNumber', code: this.state.cardNumberError },
              { param: 'cardExpiry', code: this.state.cardExpiryError },
              { param: 'cardCvv', code: this.state.cardCvvError }
            ]}
            translate={translate}
          />
          <FlexBox gap="s">
            <Box flex="3">
              <Label htmlFor="cardNumber">Credit card number</Label>
              <Box className="geoSuggest__textInputWrapper">
                <MaskedInput
                  autoComplete="cc-number"
                  mask={this.state.cardTypeMask}
                  name="cardNumber"
                  size={20}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  placeholder="XXXX XXXX XXXX XXXX"
                  placeholderChar=" "
                  pattern="\d*"
                  value={this.state.cardNumber}
                  className={`geoSuggest__textInput ${this.buildFieldStatus(
                    'cardNumber'
                  )}`}
                  required
                />
              </Box>
            </Box>
            <Box flex="1">
              <Label htmlFor="cardExpiry">Expiration</Label>
              <Box className="geoSuggest__textInputWrapper">
                <MaskedInput
                  autoComplete="cc-exp"
                  mask="11/1111"
                  name="cardExpiry"
                  placeholder="MM/YYYY"
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  placeholderChar=" "
                  pattern="\d*"
                  value={this.state.cardExpiry}
                  className={`geoSuggest__textInput ${this.buildFieldStatus(
                    'cardExpiry'
                  )}`}
                  required
                />
              </Box>
            </Box>
            <Box flex="1">
              <Label htmlFor="cardCvv">
                <span>CVV</span>
              </Label>
              <Box className="geoSuggest__textInputWrapper">
                <MaskedInput
                  autoComplete="cc-csc"
                  mask="1111"
                  name="cardCvv"
                  size={4}
                  onChange={this.onChange}
                  onBlur={this.onBlur}
                  placeholder="XXX"
                  placeholderChar=" "
                  pattern="\d*"
                  value={this.state.cardCvv}
                  className={`geoSuggest__textInput ${this.buildFieldStatus(
                    'cardCvv'
                  )}`}
                  required
                />
              </Box>
            </Box>
          </FlexBox>
        </Box>
        <Spacer size="l" />
        <TextH2>Billing Information</TextH2>
        <PaymentCustomer
          errors={[
            { param: 'customerName', code: this.state.customerNameError },
            { param: 'customerEmail', code: this.state.customerEmailError },
            {
              param: 'customerTelephone',
              code: this.state.customerTelephoneError
            },
            {
              param: 'customerPostalCode',
              code: this.state.customerPostalCodeError
            }
          ]}
          translate={translate}
        />
        <FormField
          labelText="Full Name"
          labelHtmlFor="customerName"
          inputProps={{
            autoComplete: 'name',
            name: 'customerName',
            onChange: e => this.onChange(e),
            onBlur: e => this.onBlur(e),
            placeholder: 'Jane Smith',
            value: this.state.customerName,
            isError: this.buildFieldStatus('customerName') === 'invalid',
            inputWidth: 'auto'
          }}
        />
        <FlexBox gap="s">
          <Box flex="1">
            <FormField
              labelText="Email"
              labelHtmlFor="customerEmail"
              inputProps={{
                autoComplete: 'email',
                type: 'email',
                name: 'customerEmail',
                onChange: e => this.onChange(e),
                onBlur: e => this.onBlur(e),
                placeholder: 'name@email.com',
                value: this.state.customerEmail,
                isError: this.buildFieldStatus('customerEmail') === 'invalid',
                inputWidth: 'auto'
              }}
            />
          </Box>
          <Box flex="1">
            <FormField
              labelText="Phone"
              labelHtmlFor="customerTelephone"
              inputType="phone"
              inputProps={{
                autoComplete: 'tel',
                type: 'tel',
                name: 'customerTelephone',
                onChange: phone => this.onChangePhone(phone),
                onBlur: () => this.onBlurPhone(),
                value: this.state.customerTelephone,
                isError:
                  this.buildFieldStatus('customerTelephone') === 'invalid',
                placeholder: '+1 (123) 123-1234',
                inputWidth: 'auto'
              }}
            />
          </Box>
        </FlexBox>
        <FormField
          labelText="Postal Code"
          labelHtmlFor="customerPostalCode"
          inputType="number"
          inputProps={{
            autoComplete: 'postal-code',
            name: 'customerPostalCode',
            onChange: e => this.onChange(e),
            onBlur: e => this.onBlur(e),
            placeholder: '12345',
            value: this.state.customerPostalCode,
            isError: this.buildFieldStatus('customerPostalCode') === 'invalid',
            inputWidth: 's',
            removeBottomSpacer: true
          }}
        />
        <Spacer size="l" />
        <Divider />
        <Spacer size="l" />
        <CallToActionButton
          isDisabled={this.hasInvalidFields()}
          onClick={() => this.handleSubmit}
          isFullWidth
          size="large"
        >
          Process Authorization
        </CallToActionButton>
        <Spacer size="m" />
        <TextCaption>
          <div
            dangerouslySetInnerHTML={{
              __html: translate(`cx.global.book_confirm.rules`, {
                property_url: `/listings/${this.props.slug}`
              })
            }}
          />
        </TextCaption>
        {this.props.rental_agreement ? (
          <>
            <Spacer />
            <Box>{translate(`cx.global.book_confirm.contract`)}</Box>
            <Link to={this.props.rental_agreement.pdf.url} target="_blank">
              {translate(`cx.global.book_confirm.contract_link`)}
            </Link>
            .
          </>
        ) : null}
      </>
    );
  }
}
