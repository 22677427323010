import React from 'react';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';
import {
  Panel,
  TextBody,
  FlexBoxGrid,
  TextCaption
} from '@directsoftware/ui-kit-web-admin';
import ListingsDetailsContentBlock from '../../listing/shared-v2/listings-details-content-block';
import { selectReceiptState } from '../../../redux/slices/receipt';

const ReceiptBookingInfo = props => {
  const receiptState = useSelector(selectReceiptState);
  return (
    <ListingsDetailsContentBlock label="Reservation Info">
      <FlexBoxGrid columns={3}>
        <Panel padding="s">
          <TextCaption textTransform="uppercase" weight="regular" isFullWidth>
            {receiptState.isVehicle ? 'Pick Up Time' : 'Check-in Time'}
          </TextCaption>
          <TextBody weight="semibold" textColor="dark-gray">
            {moment(receiptState.booking.check_in_time).format('hh:mm A')}
          </TextBody>
        </Panel>
        <Panel padding="s">
          <TextCaption textTransform="uppercase" weight="regular" isFullWidth>
            {receiptState.isVehicle ? 'Drop Off Time' : 'Check-out Time'}
          </TextCaption>
          <TextBody weight="semibold" textColor="dark-gray">
            {moment(receiptState.booking.check_out_time).format('hh:mm A')}
          </TextBody>
        </Panel>
        <Panel padding="s">
          <TextCaption textTransform="uppercase" weight="regular" isFullWidth>
            Staying With You
          </TextCaption>
          <TextBody weight="semibold" textColor="dark-gray">
            {`${receiptState.booking.num_guests} ${
              receiptState.booking.num_guests > 1 ? 'Guests' : 'Guest'
            }`}
          </TextBody>
        </Panel>
      </FlexBoxGrid>
    </ListingsDetailsContentBlock>
  );
};

ReceiptBookingInfo.propTypes = {};

export default ReceiptBookingInfo;
